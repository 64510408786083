.scrollTop {
  position: fixed;
  width: 5vw;
  bottom: 20px;
  right: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes htmlFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  animation: htmlFadeIn 0.5s;
  transition: opacity 0.5s;
}

@media only screen and (max-width: 600px) {
  .mainOverviewFirstColumn {
    margin-top: 3vh;
  }
}

body,
html {
  min-height: 100%;
  font-family: "Poppins", sans-serif;
}
body {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}
